<template>
    <div class="image-gallery">
        <media-header />
        <div class="gallery faded-content-for-list-update">
            <div class="first-nav gallery-nav">
                <mouse-changer :type="mouseTypes.VideoScale">
                    <div
                        v-for="item in firstLine"
                        :key="item.id"
                        style="position: relative; display: flex;"
                        @mouseover="showContent(item)"
                        @mouseleave="device.size > 768 ? (show = false) : ''"
                    >
                        <video
                            @click="showVideo(item)"
                            :id="item.id"
                            muted
                            preload
                            loop
                            playsinline
                            :poster="item.image"
                        >
                            <source :src="item.video" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div :class="{ active: show }" class="hidden-content">
                            <div class="content-container">
                                <div class="title">{{ item.title }}</div>
                                <div class="down">
                                    <span class="text">{{ item.date }}</span>
                                    <div class="line"></div>
                                    <span class="text">
                                        {{ VidDuration }}
                                        {{ _tr("წუთი") }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </mouse-changer>
            </div>
            <div class="second-nav gallery-nav">
                <mouse-changer :type="mouseTypes.VideoScale">
                    <div
                        v-for="item in secondLine"
                        :key="item.id"
                        style="position: relative; display: flex;"
                        @mouseover="showContent(item)"
                        @mouseleave="device.size > 768 ? (show = false) : ''"
                    >
                        <video
                            @click="showVideo(item)"
                            :id="item.id"
                            muted
                            preload
                            loop
                            playsinline
                            :poster="item.image"
                        >
                            <source :src="item.video" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div :class="{ active: show }" class="hidden-content">
                            <div class="content-container">
                                <div class="title">{{ item.title }}</div>
                                <div class="down">
                                    <span class="text">{{ item.date }}</span>
                                    <div class="line"></div>
                                    <span class="text">
                                        {{ VidDuration }}
                                        {{ _tr("წუთი") }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </mouse-changer>
            </div>
            <div class="third-nav gallery-nav">
                <mouse-changer :type="mouseTypes.VideoScale">
                    <div
                        v-for="item in thirdLine"
                        :key="item.id"
                        style="position: relative; display: flex;"
                        @mouseover="showContent(item)"
                        @mouseleave="device.size > 768 ? (show = false) : ''"
                    >
                        <video
                            @click="showVideo(item)"
                            :id="item.id"
                            muted
                            preload
                            loop
                            playsinline
                            :poster="item.image"
                        >
                            <source :src="item.video" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div :class="{ active: show }" class="hidden-content">
                            <div class="content-container">
                                <div class="title">{{ item.title }}</div>
                                <div class="down">
                                    <span class="text">{{ item.date }}</span>
                                    <div class="line"></div>
                                    <span class="text">
                                        {{ VidDuration }}
                                        {{ _tr("წუთი") }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </mouse-changer>
            </div>
        </div>
        <p v-if="firstLine.length == 0" class="msg">Videos not Found</p>

        <paginate
            v-model="activePage"
            :page-count="pageCount"
            v-if="pageCount > 1"
            container-class="pagination"
            :page-range="15"
            :prev-text="prev"
            :next-text="next"
            :click-handler="paginationClickCallback"
        >
            <span slot="breakViewContent">
                <svg width="16" height="4" viewBox="0 0 16 4">
                    <circle fill="#999999" cx="2" cy="2" r="2" />
                    <circle fill="#999999" cx="8" cy="2" r="2" />
                    <circle fill="#999999" cx="14" cy="2" r="2" />
                </svg>
            </span>
        </paginate>
    </div>
</template>

<script>
import MediaHeader from "@/components/custom/MediaHeader";
import MouseChanger from "@/components/custom/MouseChanger";
import { mapActions, mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import getDataList from "@/mixins/getDataList";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    mixins: [getDataList],
    components: {
        MediaHeader,
        MouseChanger,
        Paginate
    },
    data() {
        return {
            show: false,
            DurationData: null
        };
    },
    watch: {
        device: {
            immediate: true,
            handler(val) {
                if (val.size < 768) {
                    this.show = true;
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        VidDuration() {
            return parseInt(this.DurationData / 60, 10);
        },
        images() {
            let data = this.pagingDataList || this.data;

            return data?.list.map(item => {
                return {
                    video: item.video?.videoFile?.url,
                    mobileVideo: item.mobile_video?.videoFile?.url,
                    date: item?.date,
                    title: item?.title,
                    teaser: item?.teaser,
                    image: item?.cover_image?.[0]?.devices?.[this.device.type],
                    color: item?.frame_color,
                    description: item?.description,
                    id: item?.id
                };
            });
        },
        firstLine() {
            let images = this.images?.filter(
                (image, index) => (index + 1) % 3 === 1
            );

            if (this.device?.size < 1025) {
                images = this.images?.filter(
                    (image, index) => (index + 1) % 2 === 1
                );
            }
            if (this.device?.size < 769) {
                images = this.images;
            }
            return images;
        },
        secondLine() {
            let images = this.images?.filter(
                (image, index) => (index + 1) % 3 === 2
            );

            if (this.device?.size < 1025) {
                images = this.images?.filter(
                    (image, index) => (index + 1) % 2 === 0
                );
            }

            return images;
        },
        thirdLine() {
            return this.images?.filter((image, index) => (index + 1) % 3 === 0);
        }
    },
    methods: {
        ...mapActions({
            setContent: "video/setContent"
        }),
        showContent() {
            this.show = true;
            this.DurationData = document.querySelector("video").duration;
        },
        showVideo(path) {
            if (window.innerWidth < 1024) {
                this.setContent({
                    path: path.mobileVideo
                });
            } else {
                this.setContent({
                    path: path.video
                });
            }
        }
    },
    beforeDestory() {
        this.setContent(false);
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);
.hidden-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.464) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    opacity: 0;
    transition: all 0.4s 0.4s $ease-out;

    &.active {
        opacity: 1;
        transition: all 0.4s $ease-out;

        .content-container {
            .title {
                opacity: 1;
                transform: translateY(0);
                transition: all 0.4s 0.4s $ease-out;
            }

            .text {
                opacity: 1 !important;
                transform: translateX(0) !important;
            }

            .line {
                opacity: 1 !important;
                transform: translateY(0) !important;
                transition: all 0.4s 0.4s $ease-out !important;
            }

            .text {
                &:nth-child(1) {
                    transition: all 0.4s 0.45s $ease-out !important;
                }

                &:nth-child(3) {
                    transition: all 0.4s 0.6s $ease-out !important;
                }
            }
        }
    }

    .content-container {
        position: absolute;
        bottom: 10%;
        width: calc(100% - 60px);
        transform: translateX(-50%);
        left: 50%;
        color: white;
        text-align: left;

        .title {
            font-size: 21px;
            transform: translateY(20px);
            opacity: 0;
            transition: all 0.4s $ease-out;
        }

        .down {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .text {
                font-size: 16px;
                transform: translateX(20px);
                transition: all 0.4s $ease-out;
                display: block;
                opacity: 0;
            }

            .line {
                width: 2px;
                height: 11px;
                background: #e5b676;
                margin: 0 10px;
                opacity: 0;
                transition: all 0.4s $ease-out;
            }
        }
    }
}

.image-gallery /deep/ {
    .gallery {
        display: flex;
        align-items: flex-start;
        z-index: 2;
        position: relative;
        margin: -100px 225px 0;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            margin: -80px 165px 0;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin: -60px 128px 0;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin: -60px 47px 0;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin: -55px 47px 0;
        }
        @media only screen and (max-width: 767px) {
            margin: 0 16px 0;
        }

        .gallery-nav {
            display: flex;
            flex-direction: column;
            flex: 33%;
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }

            .mouse-changer {
                margin-top: 30px;
            }

            video {
                width: 100%;
            }

            @media only screen and (max-width: 1023px) {
                &.third-nav {
                    display: none;
                }
            }
            @media only screen and (max-width: 767px) {
                &.second-nav {
                    display: none;
                }
            }
        }
    }
    .msg {
        margin-top: 150px;
        font-size: 21px;
    }
    .pagination {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 144px auto 0;
        list-style-type: none;

        li {
            margin: 0 10px;
            font-weight: normal;
            font-size: 34px;
            text-align: left;
            color: #0a141c;
            transition: all 0.6s $ease-out;

            &.active {
                color: #93272c;
            }

            a {
                outline: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                -webkit-tap-highlight-color: transparent;
            }

            .next {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
</style>
